.homeScreen {
    &__page {
        width: 100%;
    }

    &__header-actions {
        width: auto;
        display: flex;
        // margin-bottom: -24px;
        align-items: center;
        align-self: flex-end;
    }

    &__searching {
        align-items: center;
        width: 340px;
        margin-right: 12px;
        // margin-bottom: -24px;
    }

    &__sorting {
        // width: 15%;
        display: flex;
        align-items: center;
        align-self: flex-end;
        // margin-bottom: -24px;

        span {
            width: 30%;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .sortBy--text {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .sort-field {
            width: 60%;
            display: flex;
            padding: var(--Spacing-m, 10px);
            gap: 8px;
            background: transparent;
            position: relative;

            input {
                width: 100%;
                border: 0px;
                background: #FFF;
                cursor: pointer;
            }

            img {
                // position: absolute;
                border: 0px;
                // right: 120px;
                // top: 20px;
                cursor: pointer;
            }
        }

        .sort-popup {
            z-index: 4;
            position: absolute;
            right: 15px;
            top: 47px;
            overflow-y: auto;
            border: 0.4px solid var(--Border-extra-light, #D4D4D4);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            background: var(--Fills-light, #FFFFFF);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            width: 80%;

            .selected-list-item {
                color: var(--Text-brand-medium, #197A56);
            }

            ul {
                li {
                    display: flex;
                    height: 48px;
                    padding: var(--Spacing-m, 16px);
                    gap: 8px;
                    align-items: center;
                    align-self: stretch;
                    border: 0.5px solid var(--Border-extra-light, #D4D4D4);
                    background: var(--Fills-light, #FFF);
                    box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                }
            }
        }
    }

    &__heading {
        color: #323232;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.48px;
    }

    &__content {
        padding: 24px 50px;
        display: flex;
        align-items: center;
        // flex-direction: column;

        .requestContainer {
            width: 100%;
            height: 664px;
            flex-shrink: 0;
            border-radius: 16px;
            border: 1px solid #0977D7;
            background: #FFF;
            margin-top: 16px;
        }

    }

    &__requests {
        display: flex;
        align-items: center;
        gap: 0px;
        margin-right: auto;

        .open--requests {
            color: #323232;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            border-radius: 8px;
            background: #F2F2F2;
            padding: 16px;
            cursor: pointer;
            height: 57px;
        }

        .active--request {
            padding: 16px;
            border-radius: 8px;
            background: #856E57;
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: -0.4px;
            cursor: pointer;
            height: 57px;
            z-index: 9;
        }

        .inactiveclosed--req {
            margin-left: -10px;
            z-index: 1;
            // width: 190px;
            padding: 16px 20px;
        }

        .inactiveopen--req {
            // margin-right: -15px;
            margin-right: -8px;
            z-index: 1;
            // width: 180px;
            width: auto;
        }
    }
}

.homeScreen__body {
    padding: 12px 50px;
}