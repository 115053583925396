@import "src/global-styles/colors";

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #197A56;
  background-image: url("../../../assets/images/tour-off.svg");
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  z-index: 10;
  
  span{
    display: none;
    margin-left: 20px;
  }
}

.floating-button:hover {
  background-color: #197A56;
  background-image: url("../../../assets/images/tour-on.svg");
  background-repeat: no-repeat;
  background-position: 10%;
  width: 162px;
  border-radius: 100px;
  color: #FFF !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  span{
    display: inline-block;
    margin-left: 20px;
  }
}