.newUser__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0px;
    margin-top: 320px;
    gap: 24px;

    .newUser__left {
        height: 353px;
        background: linear-gradient(180deg, #E9DDF6 0%, #FCE1DC 100%);
        width: 50%;
        position: relative;

        .transcript__top {
            background: rgba(255, 255, 255, 0.51);
            height: 83px;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 32px 24px;

            .newUser__transcriptLeft {
                .logo--img {
                    width: 35px;
                    height: 35px;
                }
            }

            .newUser__transcriptRight {
                .transcript--text {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    background: linear-gradient(90deg, #0A477D 0%, #197A56 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                }

                .transcript--subtext {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 18px;
                    background: linear-gradient(90deg, #894BD1 0%, #197A56 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .transcript__bottom {
            background: linear-gradient(180deg, #E9DDF6 0%, #FCE1DC 100%);
            padding: 24px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;
            height: 270px;

            .transcriptRight--text {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 300;
                line-height: 30px;
                /* 125% */
                letter-spacing: -0.48px;
            }

            .transcriptRight--subtext {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }

            .transcript__button {
                border: 1px solid rgba(25, 122, 86, 0.4);
                display: flex;
                align-items: center;
                height: 56px;
                min-width: 224px;
                padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
                gap: 8px;
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                cursor: pointer;

                .logo--btn {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    .newUser__right {
        height: 354px;
        background: #E0F1FF;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 24px;
        gap: 16px;
        position: relative;
        width: 50%;

        .newfeature__button {
            border: 1px solid rgba(25, 122, 86, 0.4);
            display: flex;
            align-items: center;
            height: 56px;
            min-width: 224px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            gap: 8px;
            color: var(--Fills-brand-medium, #197A56);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;

            .logo--btn {
                width: 18px;
                height: 18px;
            }
        }

        .newfeature--text {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: -0.48px;
        }

        .newfeature--subtext {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .newfeature--img {
        height: 150px;
        width: 203px;
        margin-top: -16px;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
    .transcript--img {
        height: 207px;
        width: 192px;
        margin-top: -16px;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
}