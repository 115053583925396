@import "src/mixins/mixins";

.expertprofile {
    &__modal {
        display: flex;
        align-items: center;
        background: transparent !important;
        width: 1100px;
        height: 94%;
        overflow: hidden;

        .show--more {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            color: var(--Fills-brand-medium, #197A56);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            gap: 4px;
        }

        .carousel-btn {
            margin: auto;
            height: 56px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-s, 8px);
            flex-shrink: 0;
            background: white;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            border: none !important;
            box-shadow: none !important;
            display: flex;
        }

        .carousel-btn:disabled {
            opacity: 0.6;
            background: var(--Colour-Grey-White, #FFF);
            color: black;
        }

        .info-option-popup {
            z-index: 110;
            position: absolute;
            right: 292px;
            bottom: 65px;
            overflow-y: auto;
            border: 0.2px solid var(--Border-extra-light, #D4D4D4);
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
            background: var(--Fills-light, #FFFFFF);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            width: 18.2%;
            color: #197A56;

            ul {
                li {
                    display: flex;
                    height: 48px;
                    padding: var(--Spacing-m, 16px);
                    gap: 8px;
                    align-items: center;
                    align-self: stretch;
                    border: 0.1px solid var(--Border-extra-light, #D4D4D4);
                    background: var(--Fills-light, #FFF);
                    box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                }
            }
        }
    }

    &__main-div {
        background: white;
        height: 100%;
        width: 100%;
        overflow: hidden;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        margin-left: 16px;
        margin-right: 16px;
    }

    &__right-title {
        float: inline-end;
        display: flex;
        color: white !important;

        .duplicate-text {
            padding: var(--Spacing-none, 8px) var(--Spacing-s, 17px) var(--Spacing-none, 8px) var(--Spacing-s, 18px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-xs, 0px);
            border-radius: var(--Spacing-none, 0px) var(--Spacing-none, 0px) var(--Spacing-xs, 4px) var(--Spacing-xs, 4px);
            background: var(--Fills-brand-dark, #0E3E1B);
            color: white;
            height: 34px;
            cursor: pointer;
        }

        .close-icon {
            margin: 20px 24px;
            cursor: pointer;
        }
    }

    &__title {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        margin-top: 20px;
        margin-left: 22px;
    }

    &__heading {
        width: 100%;
        background: #F2F2F2;
        margin: 21px 0px 2px 0px;
        padding: 24px;
        flex-direction: column;
        gap: 8px;

        .line1 {
            display: flex;
            justify-content: space-between; /* Distribute space between text and buttons */
            align-items: center;
            gap: 10px; /* Add gap between the expert name and the action buttons */
        }
        
        .expert-info {
            display: flex;
            font-size: 24px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: -0.48px;
            overflow-wrap: break-word; /* Ensures text wraps and doesn't overflow */
            word-wrap: break-word; /* Cross-browser compatibility */
            align-items: center;
        }
        
        .expert-info img {
            margin-left: 4px;
        }
        
        .action-btns {
            display: flex;
            gap: 4px;
            margin-left: auto; /* Push the action buttons to the far right */
            flex-shrink: 0;
        }
        
        .decline-btn,
        .shortlist-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            padding: 8px;
            border-radius: 4px;
            width: auto; /* Ensure buttons have a consistent size */
            font-size: 16px;
            font-weight: 400;
        }
        .shortlist-btn{
            width: 120px !important;
        }
        
        .decline-btn {
            border: 0;
            color: var(--Alerts-danger-dark, #A1150C);
            background: none;
        }
        
        .decline-btn:disabled {
            opacity: 0.4;
        }
        
        .shortlist-btn {
            border: 1px solid var(--Fills-brand-medium, #197A56);
            color: var(--Fills-brand-medium, #197A56);
            background: none;
        }
        
        .shortlist-btn:disabled {
            opacity: 0.4;
        }
        
        .shortlist-btn img,
        .decline-btn img {
            margin-right: 8px;
        }
        

        .line2 {
            display: inline-flex;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            align-items: center;
            margin-bottom: 12px;
            width: 100%;
        }
        
        .line2__sub {
            display: inline-flex;
            align-items: center;
            width: 100%;
        }
        
        .line2__child {
            display: inline-block;
        }
        
        .separator {
            margin: 0 4px; /* Adds space around the separator */
        }
        
        .expert-location {
            font-size: 14px;
            line-height: 20px;
            display: inline-flex;
            align-items: center;
        }
        
        .expert-location img {
            margin-right: 4px; /* Adds space between the pin and text */
        }
        

        .line3 {
            display: flex;
            align-items: flex-start;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            justify-content: space-between;

            .line3__sub {
                display: flex;
                align-items: flex-start;
                gap: 8px;
            }

            .line3__sub1 {
                display: flex;
                flex-direction: column;
            }

            .line3_sub1 {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            span {
                margin-right: 7px;
            }

            .normal-text {
                font-weight: 300;
                margin-left: 32px;
            }

            .profile-icons {
                background-color: white;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;

                img {
                    margin: auto;
                }

                span {
                    margin: auto;
                    color: #0977D7;
                    font-size: 12px !important;
                    font-weight: 400;
                    line-height: 18px;
                }
            }
        }
    }

    &__details {
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        padding: 0px 24px 0px 24px;

        span {
            font-weight: 700;
            display: block;
            margin-bottom: 8px;
            margin-top: 20px;
        }

        p {
            font-weight: 400;
        }

        .expert-type-list {
            display: flex;
        }

        .expert-type {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            margin-right: 10px;
            padding: 0px 6px;
            height: 24px;
            border-radius: var(--Spacing-xs, 4px);
            border: 1px solid var(--Fills-brand-medium, #197A56);

            span {
                margin: auto;
                height: 100%;
                padding-top: 2px;
            }
        }

        .screening-ques {
            display: grid;

            .qus-ans-block {
                display: -webkit-inline-box;
                align-items: center;
            }

            .qus-icon {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                background-color: var(--Colour-Grey-200, #F2F2F2);

                span {
                    margin: auto;
                }
            }

            .ans-icon {
                background: none !important;
                margin-top: 6px;
            }

            .question {
                letter-spacing: -0.24px;
                word-wrap: break-word;
                color: var(--Text-dark, #323232);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                display: flex;
                flex-wrap: wrap;
                padding: 0px 15px 0px 8px;

                span {
                    margin: auto;
                }
            }

            .answer {
                margin: 8px 12px 20px 9px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                padding: 14px;
                color: #000;
                min-height: 51px;
                background: var(--Fills-medium, #F2F2F2);

                span {
                    line-height: 20px;
                    margin: auto 2px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }


    }

    &__action-container {
        padding: 24px;
        display: flex;
        justify-content: flex-end;

        button {
            margin-right: 8px;
        }
    }
}