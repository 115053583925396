.width35 {
    width: 34.5%;
}

.is-invalid {
    background-position: right calc(0.45em + 0.1875rem) center;
}

.breadcrumbinput__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 183px;
    height: 27px;
    margin-left: 5px;

    input {
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px solid #D4D4D4;
        color: var(--Text-brand-medium, #197A56);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        padding: 10px;
        text-transform:capitalize;
    }

    .image {
        right: 10px;
        position: absolute !important;
        top: 8px;
        cursor: pointer;
        width: 10px;
        height: 14px;
    }

    .label {
        display: flex;
        height: 24px;
        padding: 8px;
        align-items: center;
        border-radius: var(--Spacing-xs, 4px);
        background: var(--Fills-medium, #F2F2F2);
        color: var(--Text-dark, #323232);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        justify-content: space-between;
        gap: 2px;
    }
}

.dropdown {

    .divider {
        width: 100%;
        display: flex;
        align-items: center;
        background: none;
    }
}

.imgOpacity {
    opacity: 0.4;
}

.breadcrumb__dropdown {
    z-index: 8;
    position: absolute;
    top: 100%;
    left: 0px;
    max-height: 261px;
    overflow-y: auto;
    gap: 0px;
    border: 0.5px 0px 0px 0px;
    border: 0.5px solid var(--Border-extra-light, #D4D4D4);
    box-shadow: 0px 16px 96px -24px #00000033;
    background: var(--Fills-light, #FFFFFF);
    width: 183px;

    .breadcrumb__links {
        height: 40px;
        line-height: 24px;
        padding: 10px;
        display: flex;
        align-items: center;
        align-self: stretch;
        border-bottom: 0.5px solid var(--Border-extra-light, #D4D4D4);
        background: var(--Fills-light, #FFF);
        box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        -webkit-line-clamp: 1;
        color: #323232 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-transform:capitalize;
    }
    .breadcrumb__linksActive{
        color: #197A56 !important;
    }
}