.Notifications__wrap {
    .notihead__wrap {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: center;

        .noti--headRight {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        img {
            cursor: pointer;
        }
    }

    .noti--head {
        color: var(--Text-dark, #323232);
        font-variant-numeric: lining-nums tabular-nums;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.48px;
    }

    .noti__body {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 540px;
        overflow-y: auto;
        padding-right: 10px;

        .noti__itemWrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
        }

        .noti__item {
            width: 100%;
            position: relative;
            padding: 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-none, 8px);
            background: var(--Fills-light, #FFF);

            .pin-badge {
                position: absolute;
                left: -12px;
            }

            .noti__itemSub {
                display: flex;
                justify-content: space-between;
                gap: 8px;
                width: 100%;

                .noti--id {
                    img {
                        float: right;
                        cursor: pointer;
                    }
                }

                .noti--text {
                    color: var(--Text-dark, #323232);
                    font-variant-numeric: lining-nums tabular-nums;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    width: 100%;
                    /* 142.857% */
                }
            }

            .noti--notiTime {
                color: var(--Text-medium, #696969);
                font-variant-numeric: lining-nums tabular-nums;
                font-family: "Henderson BCG Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }

    .no--noti {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }
}