@import "src/mixins/mixins";

.commTemplates__wrapper {
    .tablebody--check {
        width: 95px !important;
        padding: 8px 12px !important;
        max-width: unset !important;
        text-align: center !important;
    }

    .disabled--btn {
        opacity: 0.4;
    }

    .commTemplates__note {
        display: flex;
        position: absolute;
        left: 0px;
        height: 55px;
        width: 100%;
        top: 0px;
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        background: var(--Alerts-info-dark, #0A477D);
        color: var(--Colour-Grey-White, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .collapse__approveWrap {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .shareApprove__template {
        display: flex;
        align-items: center;
        gap: 8px;

        .share__template {
            a {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .approve__template {
            button {
                height: 40px;
                padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
                justify-content: center;
                align-items: center;
                gap: var(--Spacing-s, 8px);
                flex-shrink: 0;
                background: var(--Fills-brand-medium, #197A56);
                border-color: #197A56;
                color: var(--Text-light, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                display: flex;
            }
        }
    }

    .templatecount__expCollWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        margin-top: 55px;
        height: 40px;

        .expCollapse__wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            width: 105px;

            div {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 4px;
            }

            .collapse--text {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .expand--text {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    #scrollable-container {
        height: 460px;
        overflow-y: auto;
        position: relative;
    }

    .completedcalls__header th {
        position: sticky !important;
        top: 0;
        padding: 8px 16px;
        background: var(--Alerts-info-light, #E0F1FF) !important;
        border-radius: 0px !important;
        border-left: 0px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
        z-index: 6;
        font-size: 1em;
        line-height: inherit;
    }

    .completedcalls__header-border{
        border-right: 1px solid rgba(9, 119, 215, 0.250) !important;
    }

    .completedcalls__header {
        position: sticky !important;
        top: 0;
        width: 100%;
        z-index: 5;

        .exp-header--title {
            height: 48px;
            padding: 8px;
            width: 18%;

            .filter--countwrap {
                display: flex;
                align-items: center;
                gap: 2px;
            }

            .icon {
                background: transparent;
                padding-left: 0px !important;
                padding-right: 0px !important;
                margin-right: 0px;
            }

            .filter--count {
                min-width: 30px;
                display: inline-flex;
                height: var(--Spacing-24, 24px);
                padding: var(--Spacing-none, 0px) var(--Spacing-8, 8px);
                align-items: center;
                gap: var(--Spacing-4, 4px);
                flex-shrink: 0;
                border-radius: 12px;
                background: var(--Alerts-info-dark, #0A477D);
                margin-left: 4px;
                color: var(--Text-light, #FFF);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
            }
        }

        .req--idtitle {
            width: 15% !important;
        }

        .comm-header--sorttitle {
            height: 48px;
            background: var(--Alerts-info-light, #E0F1FF);
            padding: 8px 16px;
            border-radius: 0px !important;
            width: 42px;
            text-align: center;

            label {
                margin-bottom: 0;
            }
        }
    }

    .completedcalls__body tr {
        margin-bottom: 30px;
    }

    .loading--center {
        margin: auto;
    }

        .user--name {
        width: 18.5% !important;
    }

    .time--field{
        width: 18.5% !important;
    }

    .user--datalast {
        width: 5% !important;
    }

    .table--row {
        cursor: pointer;
    }

    .completedcalls__body td {
        vertical-align: middle;
        max-width: 113px;
        width: auto;
        height: 48px;
        min-height: 48px;
        max-height: 48px;
        border-left: none !important;
        border-right: none !important;
        padding: 8px 11px;
        border-top: none;
        word-wrap: break-word;
        font-size: 1em;
        line-height: inherit;

        .right--icon {
            cursor: pointer;
        }
    }

    .req--id {
        color: var(--Text-brand-medium, #197A56) !important;
        width: 15% !important;
    }

    .table--check {
        margin-top: 10px;
    }

    .table--check label {
        padding-left: 0px;
        margin: 0px;
    }

    .table--check label:before {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-8, 8px);
        border: 1px solid rgba(177, 177, 177, 1);
        border-radius: 0px;
        appearance: none;
        background-color: white;
    }

    .table--check label:after {
        background: rgba(25, 122, 86, 1);
        border-color: rgba(34, 36, 38, .35);
        color: #fff !important;
        border-radius: 0px;
        content: "✔" !important;
        position: absolute;
        font-size: 16px;
    }

    .no--pointer {
        label {
            cursor: auto !important;
        }
    }

    .tableAll--check label:before {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-8, 8px);
        border: 1px solid rgba(177, 177, 177, 1);
        border-radius: 0px;
        appearance: none;
        background-color: white;
        position: relative;
    }
    .tableAll--check{
        margin: 12.5% !important;
    }
    .tableAll--check label:after {
        background: rgba(25, 122, 86, 1);
        border-color: rgba(34, 36, 38, .35);
        color: #fff !important;
        border-radius: 0px;
        width: 20px;
        height: 20px;
        content: '\2212' !important;
        position: absolute;
        font-size: 16px;
    }

    .template--div {
        .template--cellwrap {
            border-top: none;
            border-bottom: 8px solid #F2F2F2;

            .template--cell {
                min-height: 100px;
            }
        }
    }

    .calls--table {
        border: none;
        margin: 0 !important;
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        text-align: left;
    }

    .header--div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .filter--icon {
            height: 12px;
            width: 12px;
            cursor: pointer;
            margin-left: 5px;
        }

        .header--divText {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: auto;
            text-wrap: nowrap;
            max-height: 40px;
            white-space: normal;
            overflow-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }

        .ui.floating.dropdown>.menu {

            border-radius: 0px !important;
        }

        .calls--menu {
            left: -205px !important;
            margin-top: -4px !important;
            width: 220px;
            max-height: 196px;
            border-radius: 0px !important;
            overflow-y: auto;
            fill: #FFF;
            stroke-width: 1px;
            stroke: #D4D4D4;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            border: 0.5px solid var(--Border-light, #B1B1B1);
            background: var(--Fills-light, #FFF);
            top: 30px;

            .drop--divider {
                margin: 0px;
            }

            .filter--item {
                min-height: 48px;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                overflow: hidden;
                align-items: center;
                display: flex;
                justify-content: space-between;
                gap: 8px;

                .filter__chk {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: 95%;

                }

                .filter--countText {
                    color: var(--Text-dark, #323232);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                .filter--itemClear {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: var(--Fills-brand-medium, #197A56);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    .cross--icon {
                        width: 12px;
                        height: 12px;
                    }
                }

                .filter--itemtext {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    overflow-wrap: break-word;
                    width: 80%;
                    flex: 1;
                    max-width: 80%;
                    white-space: normal;
                    word-wrap: break-word;
                }

            }
        }
    }

    .last--col {
        width: auto !important;
    }

    .templates__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .templates--title {
            font-size: 32px;
            font-weight: 300;
            line-height: 40px;
            letter-spacing: -0.02em;
            text-align: left;
            margin: 35px auto auto auto;

        }

        .templates--subtitle {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            width: 540px;
            margin: 12px auto auto auto;
        }

        .closedreq--icon {
            width: 250px;
            height: 271px;
            margin: 36px auto auto auto;
        }
    }
}