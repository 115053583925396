@import "src/mixins/mixins";

.is-invalid {
    background-position: right calc(0.45em + 0.1875rem) center;
}

.other-button {
    width: 40px;
    height: 40px;
    margin-left: 8px;
    img {
        position: unset !important;
        margin: auto;
    }
}

.add-button {
    background: var(--Fills-brand-medium, #197A56);
    border: 0px;
}

.cancel-button {
    background: transparent;
    border:1px solid #D82216;
}

.dropdown {
    &__wrapper {
        display: flex;
        width: 100%;
        position: relative; //new

        input {
            width: 100%;
        }

        .label {
            display: flex;
            height: 24px;
            padding: 8px;
            align-items: center;
            border-radius: var(--Spacing-xs, 4px);
            background: var(--Fills-medium, #F2F2F2);
            color: var(--Text-dark, #323232);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            justify-content: space-between;
            gap: 2px;
        }

        img {
            position: absolute;
            border: 0px;
            // right: 22%;
            right: 10px;
            top: 20px;
            cursor: pointer;
        }

        .dropdown--textbox {
            cursor: pointer;
            padding: 10px;
        }

        .dropdown--textbox::placeholder {
            color: var(--Text-dark, #323232);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            opacity: 0.4;
        }

        .selected--checkboxes {
            position: absolute;
            top: 50%;
            /* Position the container at 50% from the top */
            transform: translateY(-50%);
            margin-left: 8px;
            @include multilineellipsis(2);
            max-width: 93%;
            overflow-x: auto;
            display: flex;
            text-overflow: ellipsis;
            flex-wrap: wrap;
            height: auto;
            max-height: 48px;
            overflow-y: auto;


            .label {
                display: flex;
                height: 24px;
                padding: 8px;
                align-items: center;
                border-radius: var(--Spacing-xs, 4px);
                background: var(--Fills-medium, #F2F2F2);
                color: var(--Text-dark, #323232);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                justify-content: space-between;
                gap: 2px;
                margin: 4px;
            }

            .image {
                position: relative !important;
                top: 0px;
                width: 10px !important;
                height: 10px !important;
                right: 0px;
                cursor: pointer;
            }
        }

        .dropdown__popup {
            z-index: 4;
            position: absolute;
            top: 98%;
            left: 0px;
            width: 100%;
            // min-height: 261px;
            max-height: 338px;
            overflow-y: auto;
            gap: 0px;
            border: 0.5px 0px 0px 0px;
            border: 0.5px solid var(--Border-extra-light, #D4D4D4);
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.26);
            background: var(--Fills-light, #FFFFFF);

            .disabled{
                background: none !important;
                border: none !important;
            }

            label {
                margin: auto;
            }

            .custom-field {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                display: flex;
                font-weight: 400;
                line-height: 24px;
                align-items: center;
                min-height: 48px;

                img {
                    margin-right: 8px;
                    position: static;
                }
            }

            ul {
                li {
                    display: flex;
                    height: 48px;
                    padding: var(--Spacing-none, 0px) var(--Spacing-16, 16px);
                    align-items: center;
                    align-self: stretch;
                    border: 0.5px solid var(--Border-extra-light, #D4D4D4);
                    background: var(--Fills-light, #FFF);
                    box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                }

                .custom-field-li {
                    display: block;
                    height: auto;
                    padding: var(--Spacing-none, 0px) var(--Spacing-16, 16px);
                    min-height: 48px;

                    label {
                        width: 100%;
                        margin-bottom: 4px;
                    }

                    input {
                        width: 50%;
                    }

                    .custom-input {
                        display: block;
                        padding-bottom: 24.5px;
                        padding-top: 24.5px;
                    }
                }
            }
        }
    }
}