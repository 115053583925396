@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.homebanner {
  &__wrap {
    color: #FFF;
    padding: 48px var(--Spacing-3xl, 56px);
    flex-shrink: 0;
    max-height: 350px;
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: left;
    font-style: normal;
    background: linear-gradient(90deg, #0A477D 0%, #197A56 100%);
    transition: background 1s 5s linear;
}

  &__content {

    .username {
      font-size: 24px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: -0.48px;
      margin-bottom: 24px;

      img.ui.image {
        display: inline-block;
        vertical-align: text-top;
      }
    }

    .heading {
      font-size: 32px;
      font-weight: 300;
      line-height: 40px;      
      letter-spacing: -0.64px;
    }

    .details {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 16px;
      width: 100%;
    }
  }

  &__initiate_exp_btn{
    color: #FFF;
    height: 56px;
    padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-s, 8px);
    flex-shrink: 0;
    background: var(--Fills-brand-medium, #197A56);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: auto;
    display: block;
    margin-top: 24px;
    border: 0px;
    border-radius: 0px;
  }
}



