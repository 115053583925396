@import "src/global-styles/typography";
@import "src/global-styles/colors";

.alert {
    &__modal {
        min-width: 475px;
        max-width: 624px;
        height: auto;
        flex-shrink: 0;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #000;

        .close-icon {
            float: inline-end;
            margin: 12px;
        }
    }

    &__simple-wrapper{
        width: 475px;
    }

    &__wrapper {
        width: auto;
        height: 100%;
        display: block;
        background: linear-gradient(180deg, #FFF 0%, #F1EEEA 100%);
    }

    &__icon {
        width: 148px;
        height: 149px;
        flex-shrink: 0;
        display: contents;

        img {
            width: 148px;
            height: 149px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__heading {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.48px;
        margin: 24px 24px 16px 24px;
    }

    &__details {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
        margin-left: 24px;
        margin-right: 24px;

        .error-text {
            margin-right: 63px;
            margin-left: 63px;
            margin-top: 16px;
        }
    }

    &__note {
        background: rgba(133, 110, 87, 0.07);
        padding: 24px;
        margin-top: 24px;

        .note-heading {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 15px;
            margin-bottom: 16px;
            letter-spacing: -0.24px;
            text-transform: uppercase;
            display: flex;

            img {
                margin-right: 8px;
            }

            .heading-text {
                text-align: center;
            }
        }

        ul {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-left: 6px;

            li {
                margin-top: 3px;
            }

            .list-icon {
                padding-right: 6px;
            }

            div {
                display: table-cell;
            }
        }
    }

    &__action-container {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 24px;
        padding-bottom: 24px;

        button {
            margin: auto 16px;

        }

        button.primary {
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            background: #197A56;
            height: 40px;
            text-transform: capitalize;
            min-width: 115px;
            border: 1px solid #197a56;
            color: #fff;
        }

        a {
            color: #197A56;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-right: 32px;
        }
    }
}