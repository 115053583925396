    .cancellationpopup--modal {
        padding: 48px 28px;
        background: linear-gradient(180deg, #FFF 0%, #F1EEEA 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 475px;
        height: auto;
        flex-shrink: 0;

        .cancellationpopup__header {
            color: var(--CoolGray-90, #21272A);
            text-align: center;
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: -0.48px;
        }

        .cancellationpopup__body {
            margin-top: 24px;

            .body--text {
                color: var(--Text-dark, #323232);
                font-variant-numeric: lining-nums tabular-nums;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }

            .cancellationpopup--bodyText {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 16px;

                .cancellationpopup--bodyText2 {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }

                .optional {
                    color: var(--Text-medium, #696969) !important;
                    font-variant-numeric: lining-nums tabular-nums;
                    font-size: 12px !important;
                    font-style: italic !important;
                }
            }

            .cancellation__input {
                height: 49px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-4, 4px);
                flex-shrink: 0;
                border: 0.5px solid var(--Border-light, #B1B1B1) !important;
                padding-left: 8px;
            }

            input::placeholder {
                padding-left: 0px !important;
            }
        }

        .cancellationpopup__footer {
            margin-top: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            .cancel--btn {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                border: 1px solid var(--Fills-brand-medium, #197A56);
                width: 108px;
                height: 40px;
                cursor: pointer;
            }

            .approve--btn {
                color: var(--Text-light, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                background: var(--Fills-brand-medium, #197A56);
                border: 1px solid #197A56;
                display: inline-flex;
                height: 40px;
                padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
                justify-content: center;
                align-items: center;
                gap: var(--Spacing-s, 8px);
                cursor: pointer;
                flex-shrink: 0;
            }

            .approve--btn-disabled {
                opacity: 0.6 !important;
                cursor: not-allowed !important;
            }
        }
    }