@import "src/mixins/mixins";

.provider__wrap {
    width: 100%;

    .disabled{
        background: none !important;
        border: none !important;
    }

    .provider-input-fields {
        position: relative;
        width: 100%;
        display: flex;
        height: auto;
    }

    .error-input-fields {
        width: 100%;
        display: flex;
        height: 48px;
    }

    .provider__selbody {
        position: relative;

        .dropdown-img {
            cursor: pointer;
            position: absolute;
            border: 0px;
            right: 15px;
        }
        .img-top-margin {
            top: 20px;
        }
        .info-img{
            margin-left: 5px;
        }
    }

    .input__wrapper {
        position: relative;

        .field-error {
            border: var(--Spacing-none, 0.5px) solid var(--Border-danger, #D82216);
        }

        .dropdown--textbox{
            cursor: pointer;
        }

        .selected--checkboxes {
            position: absolute;
            top: 50%;
            /* Position the container at 50% from the top */
            transform: translateY(-50%);
            margin-left: 8px;
            @include multilineellipsis(2);
            max-width: 93%;
            overflow-x: auto;
            display: flex;
            text-overflow: ellipsis;
            flex-wrap: wrap;
            height: auto;
            max-height: 48px;
            overflow-y: auto;


            .label {
                display: flex;
                height: 24px;
                padding: 8px;
                align-items: center;
                border-radius: var(--Spacing-xs, 4px);
                background: var(--Fills-medium, #F2F2F2);
                color: var(--Text-dark, #323232);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                justify-content: space-between;
                gap: 2px;
                margin: 4px;
            }

            .image {
                position: relative !important;
                top: 0px;
                width: 10px !important;
                height: 10px !important;
                right: 0px;
                cursor: pointer;
            }
        }
    }


    .provider__popuperror {
        background: var(--Alerts-info-dark, #0A477D);
        display: flex;
        // height: 30px;
        padding: var(--Spacing-s, 8px);
        align-items: center;
        gap: var(--Spacing-xs, 4px);

        img {
            position: relative !important;
            top: 0px;
            padding-left: 18px;
        }

        .error--msgtext {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .top45 {
        top: 48px;
    }

    .top98 {
        top: 98%;
    }

    .provider__popup {
        z-index: 8;
        position: absolute;
        // top: calc(100% + 3px);
        // top: 98%;
        // top: 45%;
        left: 0px;
        width: 100%;
        max-height: 361px;
        overflow-y: auto;
        gap: 0px;
        border: 0.5px 0px 0px 0px;
        // opacity: 0px;
        border: 0.5px solid var(--Border-extra-light, #D4D4D4);
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.26);
        background: var(--Fills-light, #FFFFFF);

        .checkbox label {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }

    .provider__searchwrapper {
        position: relative;

        img {
            top: 0px;
            right: 0px;
        }

        .provider--textbox::placeholder {
            color: var(--Text-dark, #323232);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            opacity: 0.4;
        }
    }

    .provider__popupbody {
        display: flex;
        padding: 15px;
        height: calc(100% - 48px);

        .popup--subhead {
            display: flex;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            color: #21272A;
        }

        .popup__bodyleftsection {
            display: flex;
            flex-direction: column;
            width: 50%;

            .popup__bodyleftsection-childfirst {
                border-bottom: 1px solid #F2F2F2;
                height: auto;

                .popup__bodyleftsection-childfirst-body {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 10px;
                }
            }

            .popup__body-leftsection-childsec {
                padding-top: 15px;

                .popup__body-leftsection-tier2-body {
                    display: flex;
                    flex-direction: column;
                }
            }

            border-right: 1px solid #F2F2F2;
        }

        .popup__bodyleftsection-withoutRegionalProviders {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-right: none;
        }

        .popup__bodyRrightsection {
            padding-left: 15px;

            .popup__bodyRrightsection-regionalProviders-body {
                display: flex;
                flex-direction: column;
            }
        }
    }
}