@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

.ui.breadcrumb {
    background-color: $white;
    padding-left: 0px;
    display: flex;
    align-items: center;
    gap: var(--Spacing-s, 8px);

    .section {
        color: var(--Fills-brand-medium, #197A56);
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 20px;
    }

    .active {
        color: var(--Text-dark, #323232);
    }

    .ui.dropdown>.dropdown.icon:before {
        color: #197A56;
    }

    .ui.dropdown>.dropdown.icon:after {
        color: #197A56;
    }

    .breadcrumb-dropdown {
        .breadcrumb-dropdown-item {
            color: var(--Text-dark, #323232) !important;
            font-size: 12px !important;
            font-style: normal;
            font-weight: 400;
            justify-content: space-between;
            gap: 2px;
            display: flex;
            height: 48px;
            padding: var(--Spacing-none, 0px) var(--Spacing-16, 16px);
            align-items: center;
            align-self: stretch;
            border-bottom: 0.5px solid var(--Border-extra-light, #D4D4D4) !important;
        }
    }
    .active--item{

    }
    .breadcrumb-dropdown{
        .menu{
            border-radius: 0.5px;
        }
    }
}
.ui.breadcrumb .breadcrumb--divider {
    padding-left: 3px;
    padding-right: 3px;
}
.ui.breadcrumb{
    display: flex;
}