.customgrid {
    &__main {
        border-radius: 0px !important;
        border: 0px !important;
        .grid-header th{
            background: var(--Alerts-info-light, #E0F1FF) !important;
            border-radius: 0px !important;
            border-left: 0px !important;
            border-top: 0px !important;
            border-bottom: 0px !important;
        }

        .grid-header-border{
            border-right: 1px solid rgba(9, 119, 215, 0.139) !important;
        }
        .grid-body td{
            border-right: 1px solid #F2F2F2;
            border-bottom: 1px solid #F2F2F2;
            border-top: 0px !important;
            width: 25%;
        }

        .grid-column-left-border {
            border-left: 1px solid #F2F2F2 !important;
        }

        .grid-column-no-left-border {
            border-left: 0px !important;
        }
    }
}