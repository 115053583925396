.approver-selection-popup {
    z-index: 30;
    color: black;
    width: 475px;
    height: 192.5px;
    padding: 20px;
    position: absolute;
    right: 5px;
    background-color: #FFFFFF;
    border: 1px solid #D4D4D4;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-style: normal;

    .mail-button{
        cursor: pointer;
    }
    .approver__field {
        display: contents;
        color: black;
        gap: 16px;
        height: 40px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;

        img {
            float: inline-end;
        }
    }

    .approver-input {
        display: block;
        margin-top: 19px;
        margin-bottom: 19px;
        // height: 48px;
        align-items: center;
        border: 0.5px solid var(--Border-light, #B1B1B1);
        background: inherit;
    }
}

.tooltip-btn-div {
    margin-top: 10px;
    a {
        color: #FFF !important;
        cursor: pointer;
    }
}

.approver-mail-btn {
    height: 40px;
    padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-s, 8px);
    float: inline-end;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background-color: #197A56;

    span {
        // cursor: not-allowed;
        color: #FFF !important;
    }
}