@import "src/mixins/mixins";

.teamMembers__wrapper {
    border-radius: 8px;
    background: #FFF;
    max-height: 385px;
    height: auto;
    padding: 16px;

    .teamMembers__headWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .teamMembers__rigtheadWrap {
            display: flex;
            align-items: center;
            gap: 16px;

            .addnewteam__rightwrap {
                display: flex;
                align-items: center;
                gap: 8px;
                color: var(--Fills-brand-medium, #197A56);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                cursor: pointer;
            }
        }
    }

    .teamMembers__leftheadWrap {
        display: flex;
        align-items: center;
        gap: 8px;

        .team--headText {
            color: var(--Colors-Grays-Black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .team--count {
            display: flex;
            width: 26px;
            height: var(--Spacing-16, 16px);
            padding: 0px 4px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            border-radius: 12px;
            background: var(--Alerts-info, #0977D7);
            color: var(--Text-light, #FFF);
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .teamMembers__bodyWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
        margin-top: 16px;
        max-height: 300px;
        overflow-y: auto;

        .teamMembers__body {
            cursor: pointer;
            flex-direction: column;
            gap: 10px;
            display: flex;
            border-radius: 8px;
            border: 1px solid rgba(183, 183, 183, 1);
            padding: 8px;
            max-width: 100%;
            flex: 0 0 31%;

            .skeleton__wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;

                .react-loading-skeleton {
                    width: 100% !important;
                }
            }

            .teamMembers__bodyTop {
                display: flex;
                align-items: center;
                gap: 8px;

                .user--profile {
                    border-radius: var(--Spacing-none, 48px);
                    width: 50px;

                    .user--img {
                        height: 40px;
                        min-width: 40px;
                        max-width: 100%;
                        border-radius: 50%
                    }
                }

                .user__body {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .body--name {
                        color: #333;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px;
                        @include multilineellipsis(1);
                    }

                    .body--designation {
                        color: #333;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        @include multilineellipsis(1);
                    }
                }

                .showall__wrap {
                    color: var(--Fills-brand-medium, #197A56);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    cursor: pointer;
                }
            }

            .teamMembers__bodyBottom {
                display: flex;
                align-items: center;
                gap: 8px;

                .outlook__wrap {
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .outlook--img {
                        width: 16px;
                        height: 12px;
                    }
                }

                .user__location {
                    gap: 1px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .location--text {
                        color: #696969;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        @include multilineellipsis(1);
                    }

                    .location--img {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }

    .nores__found {
        justify-content: center;
        height: 190px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.81);
        background-image: url("../../assets/images/no-calls.svg");
        background-position: center;
        background-repeat: no-repeat;
        margin: auto;

        .nocalls__data {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            margin-top: 36px;
            height: 130px;
            width: 370px;

            .nocalls--head {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .nocalls--text {
                color: #000;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
}

.templatePage__wrapper {
    width: 100%;
    background: none;
    padding: 0px;
    margin: 0px;

    .templatePage__bodywrapper {
        width: 100%;
        background: white;
        margin: 0px;
        border-radius: 8px;

        .teamMembers__body {
            width: 270px;
            overflow-x: auto;
        }
    }
}