.upcomingcalls__wrap {
    width: 100%;
    border-radius: 8px;
    background: #FFF;

    .upcomingcalls__headwrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 16px;

        .upcomingcalls__headdata {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .upcomingcalls__headviewall {
            .eye--icon {
                height: 16px;
                width: 16px;
            }

            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--Fills-brand-medium, #197A56);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;
        }

        .upcomingcalls--head {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .upcomingcalls--number {
            display: flex;
            width: 26px;
            height: var(--Spacing-16, 16px);
            padding: 0px 4px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            border-radius: 12px;
            background: var(--Alerts-info, #0977D7);
            color: var(--Text-light, #FFF);
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 160% */
        }
    }

    .background--image {
        background-image: url("../../assets/images/no-calls.svg");
        background-position: center;
        background-repeat: no-repeat;
        margin: 16px;
    }

    .upcomingcalls__body--nores {
        height: 110px;
    }

    .upcomingcalls__body {       

        .infinite-scroll-component__outerdiv {

            height: 100%;

            .infinite-scroll-component {
                height: 100% !important;
            }
        }

        .nocalls__data {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding-top: 30px;

            .nocalls--head {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .nocalls--text {
                color: #000;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                padding: 0px 16px;
            }
        }

        .calls__datawrapper {
            height: 100%;

            .calls__data {
                padding: 18px;
                width: 100%;
                stroke-width: 1px;
                stroke: #E0F1FF;
                border: 1px solid #E0F1FF;
                display: flex;
                align-items: flex-start;
                gap: 8px;

                .calls__leftside {
                    margin-top: 4px;
                    border-radius: 50%;
                    background-color: #0977D7;
                    height: 27px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .phone--icon {
                        height: 16px;
                        width: 16px;
                    }
                }

                .calls__rightside {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: 100%;

                    .calls__userwrap {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 16px;

                        .calls__userinfo {
                            .user--name {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                            }

                            .user--designation {
                                color: #696969;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                            }
                        }
                    }

                    .call__date-type {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        justify-content: space-between;
                        padding-right: 15px;

                        .call__dateclock {
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            .call--date {
                                color: var(--Text-dark, #323232);
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                            }
                        }

                        .call--type {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            color: var(--Text-dark, #323232);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                        }
                    }
                }
            }
            .calls__dataLoader{
                display: block !important;
                margin-bottom: 8px;
            }

            .calls__data:hover {
                box-shadow: 0px 1px 6px 0px #0A477D;
            }
        }
    }
    #scrollable-container {
        overflow-y: auto;
        max-height: 350px;
        position: relative;
    }
}