.expertContact__wrap {
    display: flex;
    gap: 10px;

    .is-resinvalid{
        background-position: right calc(0.45em + 0.1875rem) center;
        padding-top: 0px;
    padding-bottom: 0px;
    }

    .expertContact__subwrap{
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
    .expertContact__body {
        display: flex;
        // align-items: center;
        width: 100%;
        gap: 10px;

        .expertContact__input-email {
            display: flex;
            gap: 15px;
            
        .input__wrapper {
            position: relative;
            width: 100%;

            .dropdown--textbox{
                cursor: pointer;
            }

            input{
                width: 100%;
                height: 48px;
            }

            .label {
                display: flex;
                height: 24px;
                padding: 8px;
                align-items: center;
                border-radius: var(--Spacing-xs, 4px);
                background: var(--Fills-medium, #F2F2F2);
                color: var(--Text-dark, #323232);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                justify-content: space-between;
                gap: 2px;
            }

            .image {
                width: 16px;
                height: 16px;
                position: absolute;
                border: 0px;
                right: 15px;
                top: 20px;
                cursor: pointer;
            }
        }  
        .email__wrapper{
            width: 100%;
            input{
                width: 100%;
                // height: 48px;
                // border-radius: 0px;
            }
        }        
        }
        .addmore__btn {
            display: inline-flex;
            border-radius: 0px;
            height: 40px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-s, 8px);
            flex-shrink: 0;
            border: 1px solid var(--Fills-brand-medium, #197A56);
            background: none;
            color: var(--Fills-brand-medium, #197A56);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            top: 5px;

            img {
                position: relative !important;
                top: 0px;
                right: 0px;
            }
        }
    }

    .dropdown {
        // border: 0.5px solid var(--Border-light, #B1B1B1);
        // background: var(--Fills-light, #FFF);
        // display: flex;
        // height: 48px;
        // padding: var(--Spacing-m, 16px);
        // align-items: center;
        // gap: 8px;
        // align-self: stretch;
        // width: 50%;

        .divider {
            width: 100%;
            display: flex;
            align-items: center;
            /* margin: 0px; */
            background: none;
        }
    }

    .imgOpacity {
        opacity: 0.4;
    }

        .provider__popup {
            z-index: 5;
            position: absolute;
            top: 47px;
            left: 0px;
            width: 100%;
            max-height: 261px;
            overflow-y: auto;
            gap: 0px;
            border: 0.5px 0px 0px 0px;
            border: 0.5px solid var(--Border-extra-light, #D4D4D4);
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.26);
            background: var(--Fills-light, #FFFFFF);

            ul {
                li {
                    display: flex;
                    height: 48px;
                    padding: var(--Spacing-none, 0px) var(--Spacing-16, 16px);
                    align-items: center;
                    align-self: stretch;
                    border: 0.5px solid var(--Border-extra-light, #D4D4D4);
                    background: var(--Fills-light, #FFF);
                    box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                }
            }
        }

}