@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.mainfooter {
  &__wrap {
    margin-left: 50px;
    margin-right: 50px;
    position: relative;
    bottom: 0;
    width: auto;
    height: 68px;
    display: flex;
    border-top: 0.75px solid #535353;
    padding: 24px 0px;
  }

  &__logo {
    display: flex;
    width: 68px;
    height: 17px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img.ui.image {
      display: inline-block;
      vertical-align: text-top;
    }
  }

  &__rightSection {
    color: var(--Fills-dark, #323232);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    position:absolute;
    right: 0;
  }
}