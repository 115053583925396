@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.errorWrapper {
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  text-align: center;
  &__heading {
    color: $gray--light;
    font-family: $sans-bold;
    font-size: 150px;
    letter-spacing: 10px;
    line-height: 168px;
    margin-bottom: 10px;
    img.ui.image{
      display: inline;
      vertical-align: baseline;
      padding: 0 15px 0 10px;
    }
  }
  &__message {
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 48px;
    letter-spacing: 3.2px;
    line-height: 53px;
    margin-bottom: 15px;
  }
  &__helpText {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 20px;
    letter-spacing: 1.33px;
    line-height: 26px;
    max-width: 60%;
    margin: 0 auto;
  }
  &__btn {
    &.ui.button.button {
      height: 45px;
      min-width: 194px;
      padding: 11px 17px 10px;
      font-size: 18px;
      letter-spacing: 1.2px;
      vertical-align:top;
      margin: 31px 15px 0;

    }
  }
}