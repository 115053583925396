.expertRequest__page {
    width: 100%;

    .expertRequest__pagehead {
        padding: 20px 50px;
    }

    .expertRequest--heading {
        color: var(--Grays-Gray700, #323232);
        margin-top: 10px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.48px;
    }

    .expertRequest__subheading {
        width: 707px;
        margin-top: 15px;
        color: var(--CoolGray-90, #21272A);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .expertRequest--subheadinglink {
            color: #197A56;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;
        }
    }

    .expertRequest--text {
        color: var(--CoolGray-90, #21272A);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-top: 20px;
    }

    .expertRequest__options {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: var(--Spacing-s, 18px);
        align-self: stretch;

        .checked {
            display: flex;
            align-items: center;

            label {
                color: var(--Text-dark, #323232);
            }

            label:hover {
                transform: scale(1);
            }
        }

        label {
            color: var(--Text-dark, #323232);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        label:hover {
            transform: scale(1.1);
            transition: all 0.3s;
        }
    }

    input[type=radio]:checked~label:after {
        background-color: #197A56 !important;
    }

    input[type=radio]:checked~label:before {
        border-color: #197A56 !important;
    }
}