@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

body,
html {
  height: auto;
  --bcgweb-body-color-rgb: 50, 50,50;
  --bcgweb-white-rgb: 255, 255, 255;
}

.layout {
  min-width: 1170px;

  .layout__container {
    padding: 0px;
  }

  .displaynone {
    display: none !important;
  }

  .main {
    height: 100%;
    width: 100%;
    background-color: $white;
    display: flex;
  }

  .layout__my-navigator {
    padding: 0px;
  }

  .right-sec {
    background: #fff;
    width: 100%;
  }

  .sidebar {
    height: 100vh;
    z-index: 1;
    display: flex;
    position: fixed;

    .black-bar {
      background: $bcg-green;
      width: 50px;
      height: 100%;
      position: relative;
      padding: 10px 5px;

      .sidebar-ele {
        background-color: $bcg-dark-green;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
      }
    }

    .chathistory {
      display: none;
      width: 260px;
      background: #F2F2F2;
      height: 100%;
      padding: 20px 10px;

      h4 {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        color: #323232;
      }
    }
  }

}

.two-lines-ellipsis {
  @include multilineellipsis(2);
}

.three-lines-ellipsis {
  @include multilineellipsis(3);
}

input {
  background-color: var(--Fills-light, #FFF) !important;
  border: 0.5px solid var(--Border-light, #B1B1B1);
}

input:focus {
  outline: none;
  box-shadow: none !important;
  border-color: transparent;
}

.disabled {
  background-color: var(--Colour-Neutral-200, #F1EEEA) !important;
}

/* scrollbar styles */
::-webkit-scrollbar {
  width: 2px !important; 
  height: 3px !important;
}

/* track (the area behind the scrollbar) */
::-webkit-scrollbar-track {
  background: #FFFFFF; 
  border-radius: 2px; 
}

/* thumb (the draggable handle) */
::-webkit-scrollbar-thumb {
  background: #696969 !important; 
  border-radius: 2px;
}

/* corner (between the vertical and horizontal scrollbar) */
::-webkit-scrollbar-corner {
  background: transparent; 
}

::-webkit-scrollbar:horizontal {
  height: 5px; /* Height of the horizontal scrollbar */
}

/* Define the scrollbar track style for the horizontal scrollbar */
::-webkit-scrollbar-track:horizontal {
  background: #FFFFFF; /* Color of the horizontal track */
}

/* Define the scrollbar thumb style for the horizontal scrollbar */
::-webkit-scrollbar-thumb:horizontal {
  background: #696969; /* Color of the horizontal thumb */
}