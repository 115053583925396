.skeleton__wrap {
    .user__skeleton {
        display: flex;
        align-items: center;
        // margin-right: 20px;
    }

    .user-skeleton__left {
        margin-right: 10px;
    }

    .user-skeleton__right {
        flex-grow: 1;
    }
}