// Primary brand colors
$bcg-green--dark: #00532f;
$bcg-green: #177b57;
$bcg-green--blue-green: #3ead93;
$bcggreentwo: #3ead92;
$bcg-green--light: #32c77f;
$bcg-green--light-blue-green: #3fad93;
$bcg-yellow: #e4ef39;
$bcg-light-yellow: #eee89a;
$bcg-red: #d50000;
$red--v1: #D82216;
$bcg-blue: #00bcd4;
$bcg-blue--light: #88c0fe;
$bcg-blue--dark: #1e455e;
$bcg-blue--darktwo: #1991eb;
$bcg-orange: #ff7002;
$bcggreenthree: #29ba74;
$bcggreenfour: #03522d;
$bcggreenfive: #197a56;
$bcggreenfivehover: #0E3E1B;
$bcggreensix: #ebfaf3;
$bcg-snow: #fffafa;
$bcggreenseven: #49aa7b;
$activegreen: #3ca46a;
$autoTaggerColor: #E0F1FF;
$bcg-green--light-blue-green: #3fad93;

// Grayscale
$black: #000000;
$blacktwo: #000000;
$gray--darkest: #333333;
$gray--700: #323232;
$gray--400: #B1B1B1;
$gray--500: #535353;
$gray--300: #D4D4D4;
$gray--darker: #707070;
$gray: #d9d9d9;
$gray--dark: #b7b7b7;
$gray--light: #f2f2f2;
$gray--lightest: #fafafa;
$gray--brownish: #707070;
$gray--whitish: #dddbda;
$checkboxgray: #979797;
$regulargray: #666666;
$regulargrey1: #888888;
$green-light: rgb(62, 173, 147);
$black-lighter: rgb(230, 230, 230);
$gray-D9D9D9: #D9D9D9;
$purple--300: #A97CDD;
$white: #ffffff;
$purple--v1: #5D289B;
$bcg-red: #d50000;
$bcg-blue: #00bcd4;
$blue-primary: rgb(0, 120, 212);
$bcg-blue--light: #88c0fe;
$bcg-blue--dark: #1e455e;
$bcg-blue--darktwo: #1991eb;
$bcg-green: #197A56;
$bcg-green-highlight: #DCF9E3;
$bcg-dark-green: #0E3E1B;
$gray--light--disable: #f2f2f2cc;
$transparent: rgba(0, 0, 0, 0); // transparent convers to initial, rgba(0,0,0,0) translates to transparent.......... compilers these days

//notifications
$notify-error: #fae6e6;
$notify-error-highlight: $bcg-red;
$notify-info: #eaf7f9;
$notify-info-highlight: $bcg-blue;
$notify-success: #ffffff;
$notify-shadow: #00000085;
$notify-success-highlight: #4caf50;
$notify-warn: #fff6d0;
$notify-warn-highlight: #ff9800;