@import "src/mixins/mixins";

.requestDetails__wrapper {
    .requestDetails__pagehead {
        padding: 20px 50px;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;


        .breadcrumb {
            display: flex;
            gap: 0px;
        }

        .details__buttonWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            .slack--icon {
                cursor: pointer;
            }

            .close--request {
                display: inline-flex;
                height: 40px;
                padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
                justify-content: center;
                align-items: center;
                gap: var(--Spacing-s, 8px);
                flex-shrink: 0;
                background: var(--Fills-brand-medium, #197A56);
                color: var(--Text-light, #FFF);
                border: 3px solid #197A56;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
            }
        }
    }

    .requestDetails__data {
        padding: 0px 50px 20px 40px;

        .details--title {
            color: var(--CoolGray-90, #21272A);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px !important;
            max-height: 90px !important;
            letter-spacing: -0.48px;
            word-break: break-word;
            max-width: 900px;
            @include multilineellipsis(3);
            text-transform: capitalize;
        }

        .tooltip-width {
            max-width: 900px;
            word-break: break-all;
            text-transform: math-auto;
        }

        .details__data {
            margin-top: 16px;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            gap: 24px;

            .details--status {
                display: inline-flex;
                height: var(--Spacing-24, 24px);
                padding: 0px var(--Spacing-8, 8px);
                align-items: center;
                gap: var(--Spacing-4, 4px);
                flex-shrink: 0;
                border-radius: 12px;
                background: var(--Alerts-info, #0977D7);
                color: var(--Text-light, #FFF);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }

            .details--item {
                display: flex;
                align-items: center;
                gap: 8px;

                .item--text {
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }
        }
    }

    .requestDetails__body {
        .tabular.menu {
            padding: 0px 20px 0px 46px;
            border-bottom: 0.5px solid #F2F2F2;

            .item {
                color: var(--Text-dark, #323232);
            }

            .active {
                border: 1px solid #F2F2F2;
                background: var(--Fills-medium, #F2F2F2);
                border-top-left-radius: 16px !important;
                border-top-right-radius: 16px !important;
                color: var(--Text-dark, #323232);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 142.857% */
            }
        }

        .active {
            background-color: #F2F2F2;
            // padding-left: 0px
            padding-left: 50px;
            padding-right: 50px;
        }

        .requestDetails__tabsbody {
            position: relative;
        }

        .edit__request {
            display: flex;
            gap: 4px;
            position: absolute;
            right: 96px;
            top: 15px;
            cursor: pointer;
            /* 150% */

            .disabled-link {
                display: flex;

                img {
                    cursor: not-allowed;
                }
            }

            span {
                display: flex;
                align-items: center;
                margin-left: 5px;
                gap: 4px;
                right: 80px;
                top: 15px;
                color: var(--Fills-brand-medium, #759d8e);
                cursor: not-allowed;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            a {
                display: flex;
                align-items: center;
                gap: 4px;
                right: 80px;
                top: 15px;
                color: var(--Fills-brand-medium, #197A56);
                cursor: pointer;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}