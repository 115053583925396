@import "src/global-styles/typography";
@import "src/global-styles/colors";

.consent {
    &__modal {
        width: 70%;
        height: auto;
        border-radius: 24px;
        padding: 0px;
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.25);
    }

    &__wrapper {
        width: 100%;
        height: auto;
        padding: 0px;
        margin: 0px;
        display: flex;
    }

    &__leftpanel {
        height: auto;
        max-height: 430px;
        width: fit-content;
        overflow: hidden;
        padding: 0px;

        img {
            width: 100%;
        }
    }

    &__rightpanel {
        height: auto;
        max-height: 430px;
        width: 70%;
        overflow: auto;
        padding-top: 24px;
        border: 0px;
    }

    &__logo {
        margin-bottom: 24px;
        img.ui.image {
            width: 217px;
            height: 36px;
            flex-shrink: 0;
        }

        &-text {

            p {
                background: linear-gradient(180deg, #0977D7 0%, #197A56 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            p {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: $gray--700;
                display: block;
                margin: 0;
            }
        }
    }

    &__heading {
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: #F48067;
        }
    }

    &__body {
        font-style: normal;
        text-align: left;
        align-items: start;
        color: #000;
        padding-right: 20px;
        padding-left: 15px;
        // margin: 20px 0;

        .heading {
            font-size: 24px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: -0.48px;
            margin-bottom: 8px;
        }

        .sub-heading {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            margin-top: 24px;
            margin-bottom: 8px;
        }

        .details {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        .terms {

            & li {
                display: flex;
                align-items: start;
                flex: 10;

                img {
                    width: 11px;
                    height: 11px;
                    flex-shrink: 0;
                    margin-right: 8px;
                    margin-top: 4px;
                }

                input {
                    flex: 1;
                    flex-grow: 0;
                    margin: 4px;
                }

                label {
                    flex-grow: 9;
                }

                a {
                    text-decoration: underline;
                    color: #000000;
                    cursor: pointer;
                }

                a:hover {
                    color: #2c2c2c;
                    text-decoration: underline;
                }
            }

        }

    }

    &__footer {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0em;
        line-height: 24px;
        text-align: left;
        color: #000000;
        border-top: 1px solid #E9DDF6;
        height: auto;
        flex-shrink: 0;

        .action-container {
            text-align: center;

            button {
                float: right;
                margin: 22px 24px 27px auto;
            }

            button.primary {
                padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
                background: #197A56;
                height: 40px;
                text-transform: capitalize;
                width: auto;
                border: 1px solid #197a56;
                color: #fff;
            }

        }
    }

}