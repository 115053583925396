.upload-label {
    margin-top: 16px;
    margin-bottom: 8px;
}

.upload-link {
    color: #197A56; 
    span{
        cursor: pointer !important;
    }
    img { 
        cursor: pointer;
        margin-right: 8px;
        margin-bottom: 3px;
    }
    input[type=file],
    input[type=file]::-webkit-file-upload-button{
        cursor: pointer !important;
    }
}

.main-upload-field {
    z-index: 2;
    position: absolute;
    height: 17px;
    padding: 0px !important;
    width: 150px;
    opacity: 0;
    cursor: pointer !important;
}