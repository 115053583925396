@import "src/global-styles/colors";
@import "src/global-styles/typography";


@font-face {
  font-family: "HendersonBCGSans-Regular";
  src: url("assets/fonts/HendersonBCGSans-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "HendersonBCGSans-Light";
  src: url("assets/fonts/HendersonBCGSans-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "HendersonBCGSans-Thin";
  src: url("assets/fonts/HendersonBCGSans-Thin.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "HendersonBCGSans-Bold";
  src: url("assets/fonts/HendersonBCGSans-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "HendersonBCGSerif-Regular";
  src: url("assets/fonts/HendersonBCGSerif-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "HendersonBCGSerif-Bold";
  src: url("assets/fonts/HendersonBCGSerif-Bold.woff") format("woff");
  font-weight: 700;
}

html {
  font-size: inherit; //22.5%;
}

body {
  font-family: "Henderson BCG Sans", sans-serif !important;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 14px;
  color: $gray--darker;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  overflow-x: auto !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  list-style-type: none;
}

h1 {
  font-family: $sans-bold;
  font-size: 30px;
  color: $gray--darker;
  line-height: 1.25em;
}

h2 {
  font-family: $sans-bold;
  font-size: 22.5px;
  color: $gray--darker;
  line-height: 1.25em;
}

h3 {
  font-family: $sans-reg;
  font-size: 17.5px;
  color: $gray--darker;
  line-height: 1.25em;
}

.width35 {
  width: 35%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 75%;
}

.width90 {
  width: 90%;
}

input {
  font-family: "Henderson BCG Sans", sans-serif !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-fields::placeholder,
input::placeholder,
textarea::placeholder {
  color: var(--Text-dark, #323232);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.4;
}

.input-fields {
  height: 48px;
  font-size: 14px !important;
  background: #FFF;
}

.input-fields:focus {
  border: 0.5px solid var(--Border-light, #2c2c2c);
  border-radius: 0px;
}

textarea:focus {
  border: 0.5px solid var(--Border-light, #2c2c2c) !important;
  border-radius: 0px;
}

textarea {
  width: 100%;
  display: flex;
  height: 144px;
  border: 0.5px solid var(--Border-light, #B1B1B1);
  background: var(--Fills-light, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: var(--Spacing-m, 16px);
  align-items: flex-start;
  gap: var(--Spacing-8, 8px);
}

.form-control {
  border-radius: 0px;
  display: flex !important;
}

textarea.form-control {
  height: 144px;
}

.help-txt {
  color: var(--Text-medium, #696969);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  span {
    cursor: help;
  }
}

.error-txt {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}

.is-invalid {
  background-position: right calc(0.45em + 0.1875rem) center;
  border: var(--Spacing-none, 0.5px) solid var(--Border-danger, #D82216);
}

.people-search-wrapper {
  position: relative;
  border: 0.5px solid var(--Border-light, #B1B1B1) !important;
  height: 48px;
  background-color: rgb(255, 255, 255);

  .search--icon {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

.custom-tooltip {
  z-index: 999;
}

.grid-header-count {
  color: var(--Grays-Gray700, #323232);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;

  .count--badge {
    display: flex;
    width: 26px;
    height: var(--Spacing-16, 16px);
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--Alerts-info, #0977D7);
    color: var(--Text-light, #FFF);
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.custom--profile--pic {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  color: white;
}

.user--img {
  border-radius: 50%;
  border: var(--Spacing-none, 1px) solid #B1B1B1;
}

.default-button {
  display: flex;
  padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
  justify-content: center;
  align-items: center;
  border: 0px;
  color: var(--Text-light, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  gap: var(--Spacing-s, 8px);
  flex-shrink: 0;
  background: var(--Fills-brand-medium, #197A56);
}


button:disabled {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}

.disable-icon-block {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}

.field-dropdown {
  background: transparent !important;
}

.mini-dropdown {
  display: flex;
  padding: var(--Spacing-m, 8px);
  background: transparent;

  input {
    width: 94px;
    border: 0px;
    background: transparent !important;
    cursor: pointer;
  }

  img {
    border: 0px;
    cursor: pointer;
  }
}

.mini-dropdown-popup {
  z-index: 4;
  position: absolute;
  top: 193px;
  overflow-y: auto;
  border: 0.4px solid var(--Border-extra-light, #D4D4D4);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: var(--Fills-light, #FFFFFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .selected-list-item {
    color: var(--Text-brand-medium, #197A56);
  }

  ul {
    li {
      display: flex;
      height: 48px;
      padding: var(--Spacing-m, 16px);
      gap: 8px;
      align-items: center;
      align-self: stretch;
      border: 0.5px solid var(--Border-extra-light, #D4D4D4);
      background: var(--Fills-light, #FFF);
      box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
}

.outline-btn {
  border: 1px solid var(--Fills-brand-medium, #197A56);
  height: 40px;
  color: var(--Fills-brand-medium, #197A56);
  background-color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

button.outline {
  padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
  color: #197A56;
  height: 40px;
  text-transform: capitalize;
  min-width: 115px;
  border: 1px solid #197a56;
  background: transparent;
}

.div-bottom-border {
  border-bottom: 1px #E0F1FF solid;
}

.noRecFound {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 24px;
}

.small-tooltip {
  width: 300px !important;
  z-index: 100 !important;
}

.edit-caseteam {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;

  .duplicate-selection {
    background: #A1150C !important;
  }

  .saved-case-team-member {
    margin: 4px;
    padding: 6px;
    border-radius: 16px;
    background: #197A56;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--Text-light, rgba(255, 255, 255, 1));
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;

    span {
      margin: auto;
      width: max-content;
    }
  }
}

.mandatory {
  color: #A1150C;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-left: 4px;
}

.form-control:disabled {
  background-color: #f0f0f0 !important;
  border: 1px solid #b1b1b1;
}

//common css of popup screens
.model-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.model-body {
  overflow: auto;
  width: 100%;
  padding-bottom: 24px;
}

//custom style react-toastify
.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--warning,
.Toastify__toast--error {
  background: white !important;
  color: #000 !important;
}

.Toastify__progress-bar--info {
  background: #0977D7 !important;
}

.Toastify__progress-bar--success {
  background: #197A56 !important;
}

.Toastify__progress-bar--warning {
  background: #FFCF24 !important;
}

.Toastify__progress-bar--error {
  background: #A1150C !important;
}

.Toastify__toast--success::before {
  margin: auto 5px;
  content: url("assets/images/tick-outlined.svg");
}

.Toastify__toast--warning::before {
  margin: auto 5px;
  content: url("assets/images/warning-black.svg");
}

.Toastify__toast--info::before {
  margin: auto 5px;
  content: url("assets/images/info-outline.svg");
}

.Toastify__toast--error::before {
  margin: auto 5px;
  content: url("assets/images/info-outline.svg");
}

.Toastify__toast--success::after,
.Toastify__toast--error::after,
.Toastify__toast--warning::after,
.Toastify__toast--info::after {
  margin: auto;
  content: url('assets/images/close-black.svg');
}

//grid filters/action list design
.grid--dropdown {
  background: transparent;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px;
}

//checkbox design
.een-checkbox label:before {
  border: 1px solid rgba(177, 177, 177, 1);
  border-radius: 0px !important;
  appearance: none;
  background-color: white;
}

.een-checkbox label:after {
  background: rgba(25, 122, 86, 1);
  border-color: rgba(34, 36, 38, .35);
  color: #fff !important;
  border-radius: 0px;
  content: '\e800' !important;
  padding: auto !important;
}

.filter--checkBox:checked {
  background-color: #197A56 !important;
  border: none;
}

.filter--checkBox:checked::after {
  content: "✔";
  color: white;
  font-size: 16px;
}

.filter--checkBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-8, 8px);
  border: 1px solid rgba(177, 177, 177, 1) !important;
  border-radius: 0px;
  height: 16px;
  width: 16px;
  appearance: none;
  background-color: white;
  position: relative;
}

.field-divider {
  width: 118px;
  height: 0.5px !important;
  background: #0977D7;
  margin-top: 24px;
  margin-bottom: 24px;
}

//grid css
.grid__body {

  td {
    vertical-align: middle;
    max-width: 113px;
    width: auto;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    padding: 8px !important;
    border-top: none;
    word-wrap: break-word;
    border-left: 1px solid #F2F2F2 !important;
    // border: 8px solid #F2F2F2;

    .right--icon {
      cursor: pointer;
    }
  }

  .width12 {
    width: 12.5% !important;
  }

  .widthnew {
    width: 12.7% !important;
  }

  .rowShortlistedClr {
    background-color: #FFFBE8;
  }

  .rowDefaultClr {
    background-color: inherit;
  }

  tr {
    margin-bottom: 30px;
  }

  tr:first-child td {
    border-top: none;
  }

  tr td:nth-of-type(2) {
    border-left: none !important;
  }

  .loading--center {
    margin: auto;
  }

  .tablebody--check {
    width: 60px !important;
    padding: 8px !important;
    max-width: unset !important;
    text-align: center !important;
  }

  .user--data {
    width: 17.8% !important;

    .expert--tooltip {
      max-width: 300px !important;
      margin-top: -10px;
    }
  }

  .costWidth {
    width: 12.4% !important;
  }

  .commentWidth {
    width: 17.2% !important;
  }

  .countryWidth {
    width: 12% !important;
  }

  .screeningWidth {
    width: 17.5% !important;
  }

  .width12 {
    width: 13% !important;
  }

  .user--name {
    width: 17.5% !important;
    cursor: pointer;
    padding-left: 0px !important;
  }

  .user--datalast {
    width: 5% !important;
  }
}

.noBorderRight {
  border-right: none !important;
}

.noBorderLeft {
  border-left: none !important;
}

.right-sliding-pane {
  z-index: 110 !important;
}

.approveTemplates {
  &__bodySubText {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  &__designation {
    text-align: center;
  }
}

.people--error {
  color: var(--Alerts-danger-dark, #A1150C) !important;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.left--align{
  text-align: left;
}
// .react-joyride__spotlight{
//   transform: translateY(140px);
// }
.btn-primary {
  background-color: #197a56 !important;
  border-color: #197a56 !important;
}

.btn-text{
  color: #197a56 !important;
  background-color: transparent !important;
}