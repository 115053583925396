/* Tooltip with tail style */
.popup-tooltip {
    position: absolute;
    z-index: 9999;
    display: inline-block;
    border-radius: 4px;
    padding: 10px;
    background-color: #1e1e1e;
    color: white;
    min-width: 400px !important;
    text-align: center;
}
.green-icon{
    cursor: pointer;
}
.tooltip--headWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tooltip--head {
    color: var(--Text-dark, #323232);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.tooltip--btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    button {
        min-width: 100px;
    }
}

.decline--input {
    width: 100%;
    height: 54px;
    padding: 10px;
}

.decline--input:focus {
    border: 0.5px solid var(--Border-light, #B1B1B1);
    background: var(--Fills-light, #FFF);
}

.tooltip--optional {
    color: var(--Text-dark, #323232);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
}

.popup-tooltip .ui.popup .content {
    padding: 0;
    margin: 0;
}

.decline-btn {
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.decline-btn:disabled {
    cursor: not-allowed;
}

.decline-btn img {
    margin-right: 8px;
}