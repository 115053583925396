.comment-block {
    margin-top: 16px;
}
.pane--head{
    align-items: center;
    color: #323232;
    color: var(--Grays-Gray700, #323232);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 12px;
}

.comment-user-info {
    display: flex;

    .comment-user-profile {
        width: 40px;
        height: 40px;
    }

    .comment-pic-holder {
        width: 100%;
        height: 100%;
    }

    .commenter-details {
        margin-left: 8px;
        font-style: normal;
        width: 100%;

        span {
            display: block;
        }
        .commenter-date-name{
            display: flex;
            width: 100%;
            align-items: center;
        }

        .commenter-name {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
        }

        .commenter-title {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #333;
        }
    }
}

.comment-text {
    margin-left: 43px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
}

.comment-time {
    font-size: 10px !important;
    line-height: 16px !important;
    color: #696969 !important;
    margin: auto;
    margin-right: 0px !important;
}
.comments_error{
    margin-top: 10px;
}