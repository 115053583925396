  .phoneInput__wrap {
    position: relative;
    width: 49%;
    

  .react-tel-input {
    .flag-dropdown {
      border: 0.5px solid var(--Border-light, #B1B1B1);
      border-radius: 0.5px;

      .arrow {
        border-top: 4px solid #197A56;
      }

      .up {
        border-bottom: 4px solid #197A56;
        border-top: 0px;
      }
    }

    input {
      display: flex;
      height: 48px;
      align-items: flex-start;
      gap: var(--Spacing-8, 8px);
      align-self: stretch;
      border: 0.5px solid var(--Border-light, #B1B1B1);
      background: var(--Fills-light, #FFF);
      border-radius: 0.5px;
    }
  }

    .selected-flag {
      background: var(--Fills-light, #FFF);
      border-radius: 0.5px;
    }

    .number {
      width: 100%;
      margin: 0px;

      .form-control {
        width: 100%;
      }

      input:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .valid--img {
      position: absolute;
      right: 10px;
      top: 15px;

    }
  }