    .compare-profile-modal {
        width: 80%;
        height: 80%;
        padding: 0px 24px 24px 24px;

        .window-type {
            text-transform: capitalize;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: sticky;
            z-index: 1000;
            background-color: #FFF;
            top: 0;
            padding-top: 24px;
            padding-bottom: 24px;

            .header__right {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;

                .add-experts {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: var(--Fills-brand-medium, #197A56);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    cursor: pointer;
                    .add-experts-btn{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                }

                .download--border {
                    border: 1px solid var(--Fills-brand-medium, #197A56);
                    padding: 8px;
                }
            }

            .expert-search-field{
                width: 240px;
            }

            .compare__header {
                color: var(--CoolGray-90, #21272A);
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -0.48px;
                position: sticky;
                z-index: 10;

                .compare--name {
                    position: sticky;
                    top: 0;
                    z-index: 1000;
                }
            }

            .compare--details {
                cursor: pointer;
            }
        }

        .table-container {
            overflow-y: auto;
            width: 100%;
            tr {
                border: 1px solid #F2F2F2;
            }
        }

        .calls--table {
            min-width: 1000px;
            table-layout: auto;
        }

        .heading-header-cell {
            background-color: #EDE9E4;
            overflow: hidden;
            color: var(--Text-dark, #323232);
            text-overflow: ellipsis;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            border: 1px solid #F2F2F2;
            position: sticky;
            top: 0;
            z-index: 10;
            left: 0;
            min-width: 235px;
        }

        .header-cell {
            border: 1px solid #F2F2F2;
            position: sticky;
            top: 0;
            background: #F3F0ED;
            z-index: 10;
            left: 0;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            align-items: center;
            gap: var(--Spacing-xs, 4px);
            flex-shrink: 0;
            overflow: hidden;
            color: var(--Text-dark, #323232);
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            min-width: 235px;
        }

        .data-cell {
            overflow: visible;
            border: 1px solid #F2F2F2;
            background: var(--Fills-light, #FFF);
            padding: 0px var(--Spacing-m, 16px);
            align-items: center;
            gap: var(--Spacing-xs, 4px);
            flex-shrink: 0;
            color: var(--CoolGray-90, #21272A);
            text-overflow: ellipsis;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            min-width: 289px;

            .exp--name {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .exp--nameLeft {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    cursor: pointer;
                }

                .exp--nameRight {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    cursor: pointer;
                }
            }

            .expItem {
                border-radius: var(--Spacing-xs, 4px);
                border: 1px solid rgba(25, 122, 86, 0.4);
                padding: 4px;
                margin: 0px 4px;
            }

            .nomarginLeft {
                margin-left: 0px !important;
            }

            .red--color {
                color: var(--Alerts-danger-dark, #A1150C);
            }

            .cost--container {
                display: flex;
                align-items: center;
                gap: 4px;

                .dollar--icon {
                    background: var(--Colour-Grey-200, #F2F2F2);
                    height: 16px;
                    width: 16px;
                    cursor: pointer;
                    border-radius: 50%;
                    padding: 4px;
                }
            }

            .icon {
                background: transparent;
                padding-left: 0px !important;
                padding-right: 0px !important;
                margin-right: 0px;
            }

            .action-menu-div {
                width: 100px;
                position: relative;
            }

            .sort-popup {
                z-index: 9999999;
                position: absolute;
                top: 15px;
                border: 0.4px solid var(--Border-extra-light, #D4D4D4);
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                background: var(--Fills-light, #FFFFFF);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                min-width: 166px;
                width: auto;
                height: auto;
                right: -20px;

                ul {
                    li {
                        display: flex;
                        height: 48px;
                        padding: var(--Spacing-m, 16px);
                        gap: 8px;
                        color: #197A56;
                        align-items: center;
                        align-self: stretch;
                        border: 0.5px solid var(--Border-extra-light, #D4D4D4);
                        border-top: 0px;
                        background: var(--Fills-light, #FFF);
                        box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
                        cursor: pointer;

                        &:last-child {
                            color: #A1150C;
                        }
                    }

                    .disabled-action {
                        opacity: 0.4 !important;
                        cursor: not-allowed;
                    }
                }
            }

            .expertName--menu {
                width: 195px !important;
                left: -186px !important;
                top: 34px;
                border: none;
                background: white;

                .item {
                    padding: 0px !important;
                    background: white;
                    border: none;
                }

                .dropdown--search {
                    padding: 8px;
                    border: none;
                }

                .search--input {
                    height: 30px;
                    border-radius: 0px;
                    fill: var(--Fills-light, #FFF);
                    stroke-width: 0.5px;
                    stroke: var(--Border-extra-light, #D4D4D4);
                    filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.20));
                    background: white;
                }
            }
        }

        .data-cell.hidden {
            visibility: hidden;
        }

        .no--border {
            border-bottom: none !important;
            color: #A1150C !important;
        }

        .custom-input {
            border: 0.4px solid var(--Border-extra-light, #D4D4D4);
            border-top: none;
            label{
                padding: 0px 0px 0px 16px;
            }

            .decline--input {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                align-items: center;
                padding-left: 16px;
            }
            input{
                padding-left: 10px;
            }
        }

    }