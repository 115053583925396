@import "src/mixins/mixins";

.expertsReceived {

    &__top-filters {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        margin-top: 6px;

        .dropdown-arrow {
            width: 12px;
            height: 12px;
            cursor: pointer;
        }

        .status-filter-open {
            background: var(--Border-extra-light, #D4D4D4) !important;
        }

        .filter-status-popup {
            z-index: 12;
            position: absolute;
            width: 220px;
            overflow-y: auto;
            border: 0.4px solid var(--Border-extra-light, #D4D4D4);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            background: var(--Fills-light, #FFFFFF);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;
            top: 30px;
            right: 0px;

            .selected-list-item {
                color: var(--Text-brand-medium, #197A56);
            }

            ul {
                li {
                    display: flex;
                    height: 48px;
                    padding: var(--Spacing-m, 16px);
                    gap: 8px;
                    align-items: baseline;
                    align-self: stretch;
                    border-bottom: 0.4px solid var(--Border-extra-light, #D4D4D4);
                    background: var(--Fills-light, #FFF);
                    box-shadow: 0px 16px 96px -24px rgba(0, 0, 0, 0.2);
                    cursor: pointer;

                    div {
                        width: -webkit-fill-available;
                        margin: auto;
                    }

                    span {
                        margin: auto;
                    }
                }
            }
        }

        .top-filter-name {
            display: flex;
            border-radius: 12px;
            background: var(--Fills-light, #FFF);
            height: var(--Spacing-24, 24px);
            padding: var(--Spacing-none, 0px) var(--Spacing-8, 8px);
            align-items: center;
            gap: var(--Spacing-4, 4px);
            margin-right: 16px;
            cursor: pointer;
            position: relative;
        }

        .selected {
            display: flex;
            border-radius: 12px;
            background: var(--Fills-light, #DCF9E3);
            height: var(--Spacing-24, 24px);
            padding: var(--Spacing-none, 0px) var(--Spacing-8, 8px);
            align-items: center;
            gap: var(--Spacing-4, 4px);
            margin-right: 16px;
            cursor: pointer;
        }
    }

    &__header {
        display: flex;

        span {
            display: flex;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }
    }
}

.expertsReceived__wrap {
    width: 100%;
    
    
    #tooltip-id {
        max-width: 400px !important;
        white-space: pre-line;
        max-height: 120px !important;
        overflow-y: auto !important;
      }
      
    .tooltip-id {
        position: relative;
        z-index: 10000;
    }

    .sortBy__wrap {
        display: flex;
        align-items: center;
        gap: 6px;
        text-wrap: nowrap;

        .sortBy--head {
            overflow: hidden;
            color: var(--Text-dark, #323232);
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }

        .sortBy--drop {
            background-color: #F2F2F2 !important;
            border: none !important;
            border-radius: 0px;
        }

        .visible {
            border: 0.5px solid #696969 !important;
        }

        .text {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .dropdown {
            width: 180px !important;
            padding: 16px 8px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .menu {
                max-height: 250px !important;
                fill: #FFF;
                stroke-width: 1px;
                stroke: #D4D4D4;
                border-color: #F2F2F2 !important;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                border-radius: 0px;
            }

            svg {
                float: right;
            }

            .item {
                border: 1px solid #F2F2F2 !important;
                height: 48px !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .item:first-child {
                border-top: 0.5px solid #696969 !important;
            }

            .selected {
                background-color: #fff !important;

                .text {
                    color: rgba(25, 122, 86, 1);
                }
            }
        }

        .menu {
            z-index: 1000;
        }
    }

    .expertsReceived__head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;

        .grid-header-count {
            padding: 16px 8px !important;
        }

        .download__sort {
            display: flex;
            align-items: center;
            gap: 8px;

            .sort--by {
                overflow: hidden;
                color: var(--Text-dark, #323232);
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                display: flex;
                align-items: center;
                gap: 8px;
                position: relative;

                img {
                    cursor: pointer;
                }

                .sortby__dropdown {
                    z-index: 8;
                    position: absolute;
                    top: 100%;
                    left: 0px;
                    width: 285px;
                    max-height: 261px;
                    overflow-y: auto;
                    gap: 0px;
                    border: 0.5px solid var(--Border-extra-light, #D4D4D4);
                    box-shadow: 0px 16px 96px -24px #00000033;
                    background: var(--Fills-light, #FFFFFF);
                }
            }
        }

        .profile--actions {
            border: 0px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-right: 28px;
            cursor: pointer;
            line-height: 24px;
            text-wrap: nowrap;

            img {
                height: 16px;
            }
        }

        .decline--profiles {
            color: var(--Alerts-danger-dark, #A1150C);
            border: none;
        }

        .download--profiles,
        .shortlist--profiles {
            color: var(--Fills-brand-medium, #197A56);
        }

        .disabled-profile-action {
            color: var(--Fills-brand-medium, #6ab197);
        }
    }

    .noBorderRight {
        border-right: none !important;
    }

    .noBorderLeft {
        border-left: none !important;
    }

    .show--duplicate {
        width: 57.002px;
        height: 15.21px;
        transform: rotate(-90deg);
        flex-shrink: 0;
        border-radius: var(--Spacing-none, 0px) var(--Spacing-none, 0px) var(--Spacing-xs, 4px) var(--Spacing-xs, 4px);
        background: var(--Colour-Neutral-700, #856E57);
        color: var(--Text-light, #FFF);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        position: absolute;
        left: -20px;
    }

    .expertName__wrap {
        width: 90%;
    }

    .expertName__data {
        display: flex;
        align-items: center;
        gap: 2px;
        margin-left: -12px;
    }

    .expertName__badges {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        width: 18px;
    }

    .expertName {
        color: var(--Text-brand-medium, #197A56) !important;
        max-width: 90%;
    }

    .expertName--status {
        max-width: 95%;
        margin-top: 9px;
        width: auto;
        height: 24px;
        padding: var(--Spacing-none, 0px) var(--Spacing-s, 8px);
        align-items: center;
        gap: var(--Spacing-xs, 4px);
        flex-shrink: 0;
        border-radius: var(--Spacing-xs, 4px);
        background: var(--Fills-medium, #F2F2F2);
        color: var(--Text-dark, #323232);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tablebody--check {
        width: 8% !important;
        padding: 8px !important;
        max-width: unset !important;
        text-align: center !important;
    }

    .commTemplates__note {
        display: flex;
        position: absolute;
        left: 0px;
        height: 55px;
        width: 100%;
        top: 0px;
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        background: var(--Alerts-info-dark, #0A477D);
        color: var(--Colour-Grey-White, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .collapse__approveWrap {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .shareApprove__template {
        display: flex;
        align-items: center;
        gap: 8px;

        .share__template {
            button {
                border: 1px solid var(--Fills-brand-medium, #197A56);
                height: 40px;
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
            }

            a {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .approve__template {
            button {
                height: 40px;
                padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
                gap: var(--Spacing-s, 8px);
                flex-shrink: 0;
                background: var(--Fills-brand-medium, #197A56);
                border-color: #197A56;
                color: var(--Text-light, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .templatecount__expCollWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        margin-top: 55px;
        height: 40px;

        .expCollapse__wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            width: 105px;

            div {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 4px;
            }

            .collapse--text {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .expand--text {
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    #scrollable-container {
        height: 510px;
        overflow-y: auto;
        position: relative;
    }

    .countryHead {
        width: 18.1% !important;
    }

    .completedcalls__header th {
        // position: sticky !important;
        top: 0;
        padding: 8px 16px;
        background: var(--Alerts-info-light, #E0F1FF) !important;
        border-radius: 0px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
        z-index: 6;
        border-left: 1px solid rgba(34, 36, 38, .1) !important;
        font-size: 1em;
        line-height: inherit;
    }

    .completedcalls__header-border {
        border-right: 0.1px solid rgba(9, 119, 215, 0.250) !important;
    }

    .completedcalls__header {
        position: sticky !important;
        top: 0;
        width: 100%;
        z-index: 5;

        .exp-header--title {
            height: 48px;
            padding: 8px;
            width: 18%;

            .filter--countwrap {
                display: flex;
                align-items: center;
                gap: 2px;
            }

            .icon {
                background: transparent;
                padding-left: 0px !important;
                padding-right: 0px !important;
                margin-right: 0px;
            }

            .filter--count {
                min-width: 30px;
                display: inline-flex;
                height: var(--Spacing-24, 24px);
                padding: var(--Spacing-none, 0px) var(--Spacing-8, 8px);
                align-items: center;
                gap: var(--Spacing-4, 4px);
                flex-shrink: 0;
                border-radius: 12px;
                background: var(--Alerts-info-dark, #0A477D);
                color: var(--Text-light, #FFF);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
            }
        }

        .header--sorttitle {
            height: 48px;
            background: var(--Alerts-info-light, #E0F1FF);
            padding: 8px;
            border: 1px solid #E0F1FF;
            border-radius: 0px !important;
            width: 8%;
            text-align: center;
            border-right: 2px solid #E0F1FF !important;
        }
    }

    .completedcalls__body {

        .rowShortlistedClr {
            background-color: #FFFBE8;
        }

        .rowDefaultClr {
            background-color: inherit;
        }
    }

    .completedcalls__body tr {
        margin-bottom: 30px;
    }

    .loading--center {
        margin: auto;
    }

    .completedcalls__body tr:first-child td {
        border-top: none;
    }

    .completedcalls__body tr td:nth-of-type(2) {
        border-left: none !important;
    }

    .user--data {

        .expert--tooltip {
            max-width: 300px !important;
            margin-top: -10px;
        }

        .comment-link {
            display: flex;
            color: #197A56;
            cursor: pointer;
            margin-top: 5px;
        }
    }

    .item--biography {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        max-height: 76px !important;
        width: 100%;
        white-space: normal;
        box-sizing: border-box;
    }

    .user--data2.table--cell.item--biography {
        width: 300px;
        /* Adjust based on your layout */
    }

    .costWidth {
        width: 18.1% !important;
    }

    .commentWidth {
        width: 17.8% !important;
    }

    .countryWidth {
        width: 18.1% !important;
    }

    .user--name {
        cursor: pointer;
        padding-left: 0px !important;
        width: 15% !important;
    }

    .user--datalast {
        width: 8% !important;
    }

    .completedcalls__body td {
        vertical-align: middle;
        width: 18%;
        height: 48px;
        min-height: 48px;
        max-height: 48px;
        padding: 6px;
        border-top: none;
        word-wrap: break-word;
        border-left: 1px solid #F2F2F2 !important;
        border-bottom: 1px solid #F2F2F2 !important;
        font-size: 1em;
        line-height: inherit;

        .right--icon {
            cursor: pointer;
        }
    }

    .table--check {
        margin-top: 10px;
    }

    .table--check label {
        padding-left: 0px;
        margin: 0px;
    }

    .table--check label:before {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-8, 8px);
        border: 1px solid rgba(177, 177, 177, 1);
    }

    .table--filter-check {
        margin-top: 10px;
    }

    .table--filter-check label:before {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-8, 8px);
    }

    .tableAll--check {
        margin: 12.5% !important;
    }

    .header-2words {
        padding-right: 30px !important;
    }

    .tableAll--check label:before {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-8, 8px);
        border: 1px solid rgba(177, 177, 177, 1);
        border-radius: 0px;
        appearance: none;
        background-color: white;
        position: relative;
    }

    .tableAll--check label:after {
        background: rgba(25, 122, 86, 1);
        border-color: rgba(34, 36, 38, .35);
        color: #fff !important;
        border-radius: 0px;
        width: 20px;
        height: 20px;
        content: '\2212' !important;
        position: absolute;
        font-size: 16px;
    }

    .template--div {

        .template--cellwrap {
            border-top: none;
            border-bottom: 8px solid #F2F2F2;

            .template--cell {
                min-height: 100px;
            }
        }
    }

    .calls--table {
        border-radius: 0px !important;
        border: 0px !important;

        margin: 0 !important;
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        text-align: left;
    }

    .header--div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        gap: 8px;
        height: 100%;

        .dropdown--search {
            position: sticky;
            top: 0;
            background: #fff;
            padding: 8px;
            display: flex;
            align-items: center;
            opacity: 1;
            z-index: 100;
        }

        .search--input {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 4px 24px 4px 2px;
            flex: 1;
            width: 80%;
        }

        .paddingnew {
            padding: 4px 43px 4px 2px;
        }

        .search--input::placeholder {
            color: var(--CoolGray-90, #21272A);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            overflow-wrap: break-word;
            @include multilineellipsis(1);
        }

        .search--icon {
            margin-left: 0px;
            height: 16px;
            position: absolute;
            float: right;
            right: 12px;
        }

        .greensearch--icon {
            height: 30px;
            right: 9px;
        }

        .disable-icon {
            cursor: not-allowed;
        }

        .filter--icon {
            height: 12px;
            width: 12px;
            cursor: pointer;
        }

        .header--divText {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: normal;
            overflow-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }

        .ui.floating.dropdown>.menu {

            border-radius: 0px !important;
        }



        .expertName--menu {
            width: 195px !important;
            left: -186px !important;
            top: 34px;
            border: none;
            background: white;

            .item {
                padding: 0px !important;
                background: white;
                border: none;
            }

            .dropdown--search {
                padding: 8px;
                border: none;
            }

            .search--input {
                height: 30px;
                border-radius: 0px;
                fill: var(--Fills-light, #FFF);
                stroke-width: 0.5px;
                stroke: var(--Border-extra-light, #D4D4D4);
                filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.20));
                background: white;
            }
        }

        .calls--menu {
            left: -200px !important;
            margin-top: -14px !important;
            width: 210px;
            max-height: 196px;
            border-radius: 0px !important;
            overflow-y: auto;
            fill: #FFF;
            stroke-width: 1px;
            stroke: #D4D4D4;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            border: 0.5px solid var(--Border-light, #B1B1B1);
            background: var(--Fills-light, #FFF);
            top: 36px;

            .drop--divider {
                margin: 0px;
            }

            .filter--item {
                min-height: 48px;
                align-items: center;
                display: flex;
                justify-content: space-between;
                gap: 8px;

                .filter__chk {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: 95%;

                }

                .filter--countText {
                    color: var(--Text-dark, #323232);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                .filter--itemClear {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: var(--Fills-brand-medium, #197A56);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    .cross--icon {
                        width: 12px;
                        height: 12px;
                    }
                }

                .filter--itemtext {
                    color: var(--CoolGray-90, #21272A);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    overflow-wrap: break-word;
                    width: 80%;
                    flex: 1;
                    max-width: 80%;
                    white-space: normal;
                    word-wrap: break-word;
                }

            }
        }
    }

    .last--col {
        width: auto !important;
    }

    .templates__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .templates--title {
            text-align: left;
            margin: 35px auto auto auto;
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: -0.4px;

        }

        .templates--subtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            width: 540px;
            margin: 12px auto auto auto;
        }

        .closedreq--icon {
            width: 250px;
            height: 271px;
            margin: 36px auto auto auto;
        }
    }
}

.top-block {
    position: 'relative';
    display: 'inline-block';
}