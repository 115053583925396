.requestDetails__wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 16px;

    .requestDetails__leftsection {
        width: 75%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .requestDetails__rightsection {
        width: 35%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}