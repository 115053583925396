.disputcall--modal {
    padding: 48px 28px 28px 28px;
    background: linear-gradient(180deg, #FFF 0%, #F1EEEA 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 475px;
    height: auto;
    flex-shrink: 0;

    .disputcall__header {
        color: var(--CoolGray-90, #21272A);
        font-variant-numeric: lining-nums tabular-nums;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.48px;
    }

    .disputcall__body {
        .body__subText {
            color: var(--Text-dark, #323232);
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .body__inputText {
            color: var(--Text-dark, #323232);
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-top: 16px;
        }

        .disputecall__input {
            width: 100%;
            height: 72px;
            gap: var(--Spacing-4, 4px);
            flex-shrink: 0;
            border: 0.5px solid var(--Border-light, #B1B1B1) !important;
            padding-left: 8px;
            margin-top: 8px;
        }

        input::placeholder {
            padding-left: 0px !important;
        }

        .input__validation {
            border-color: #dc3545 !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        }

        .redText {
            color: #dc3545 !important;
        }

        .body__afterText {
            color: var(--Text-medium, #696969);
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }

        .sortBy--drop {
            background-color: #F2F2F2 !important;
            border: none !important;
            border-radius: 0px;
        }

        .visible {
            border: 0.5px solid #696969 !important;
        }

        .text {
            color: #000;
            font-variant-numeric: lining-nums tabular-nums;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .dropdown {
            width: 100% !important;
            padding: 16px 8px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;

            .menu {
                max-height: 210px !important;
                fill: #FFF;
                stroke-width: 1px;
                stroke: #D4D4D4;
                border-color: #F2F2F2 !important;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                border-radius: 0px;
            }

            svg {
                float: right;
            }

            .item {
                border: 1px solid #F2F2F2 !important;
                height: 48px !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .item:first-child {
                border-top: 0.5px solid #696969 !important;
            }

            .selected {
                background-color: #fff !important;

                .text {
                    color: rgba(25, 122, 86, 1);
                }
            }
        }

        .menu {
            z-index: 1000;
        }

        margin-top: 24px;
    }

    .disputcall__footer {
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .cancel--btn {
            color: var(--Fills-brand-medium, #197A56);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            border: 1px solid var(--Fills-brand-medium, #197A56);
            width: 108px;
            height: 40px;
            cursor: pointer;
        }

        .approve--btn {
            color: var(--Text-light, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            background: var(--Fills-brand-medium, #197A56);
            border: 1px solid var(--Fills-brand-medium, #197A56);
            display: inline-flex;
            height: 40px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-s, 8px);
            cursor: pointer;
            flex-shrink: 0;
        }

        .approve--btn-disabled {
            opacity: 0.6 !important;
            cursor: not-allowed !important;
        }
    }
}